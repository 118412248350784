var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"generate-report-card"},[_c('div',{staticClass:"mx-4 my-2"},[(_vm.report_card_group && _vm.report_card_group.room)?_c('h3',[_vm._v(_vm._s(_vm.report_card_group.title)+" - "+_vm._s(this.report_card_group.room.sections))]):_vm._e(),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.moment(_vm.report_card_group.date).format("DD MMM YYYY"))+" ")])],1),_c('v-card',{staticClass:"mx-2 my-2"},[(_vm.report_card_group && _vm.report_card_group.parameter_groups)?_c('v-data-table',{class:(!_vm.report_card_group.is_draft && !_vm.is_adding && !_vm.is_editing) ? 'row-pointer' : null,attrs:{"headers":_vm.headers,"items":_vm.report_card_group.report_cards,"disable-pagination":"","hide-default-footer":""},on:{"click:row":function (item) { return (!_vm.report_card_group.is_draft && !_vm.is_adding && !_vm.is_editing) ? _vm.$router.push({ name: 'ViewReportCard', params: { report_id : item.id } }) : null; }},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"header",fn:function(){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('td',{staticClass:"primary--text"},[_c('strong',[_vm._v(" Group Name ")])]),_c('td',{staticClass:"blue--text text-center"},[_c('strong',[_vm._v(" Assignment ")])]),_vm._l((_vm.report_card_group.parameter_groups),function(group){return _c('td',{key:group.id,staticClass:"text-center primary--text",attrs:{"colspan":group.group_parameters.length}},[_c('strong',[_vm._v(_vm._s(group.title))])])})],2)])]},proxy:true}:null,_vm._l((_vm.parameters),function(parameter){return {key:("item.parameter-" + (parameter.id)),fn:function(ref){
var item = ref.item;
return [_c('div',{key:parameter.id},[(item.report_card_grades.find(function (grade) { return grade.parameter == parameter.id; }))?_c('v-rating',{attrs:{"background-color":"primary lighten-3","color":"primary","readonly":!(_vm.is_adding || _vm.is_editing)},on:{"input":_vm.autoSave},model:{value:(item.report_card_grades.find(function (grade) { return grade.parameter == parameter.id; }).star_count),callback:function ($$v) {_vm.$set(item.report_card_grades.find(function (grade) { return grade.parameter == parameter.id; }), "star_count", _vm._n($$v))},expression:"item.report_card_grades.find(grade => grade.parameter == parameter.id).star_count"}}):_c('div',[_c('span',[_vm._v("-")])])],1)]}}}),{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [(!_vm.report_card_group.is_draft && !_vm.is_adding && !_vm.is_editing)?_c('router-link',{attrs:{"to":{ name: 'ViewReportCard', params: { report_id : item.id } }}},[_vm._v(_vm._s(item.student.full_name))]):_c('span',[_vm._v(_vm._s(item.student.full_name))])]}},{key:"item.assignment_star_count",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"background-color":"blue lighten-3","color":"blue","readonly":""},on:{"input":_vm.autoSave},model:{value:(item.assignment_star_count),callback:function ($$v) {_vm.$set(item, "assignment_star_count", _vm._n($$v))},expression:"item.assignment_star_count"}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"text-center my-4"},[(_vm.is_editing || _vm.is_adding)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red","outlined":""},on:{"click":function () {
                    if(this$1.is_editing){
                        _vm.is_editing=false;
                        _vm.getReportCardGroupDetails();
                    }
                    else{
                        _vm.$router.replace({ name: 'ReportCardDashboard'});
                    }
                }}},[_vm._v("Cancel")]):_vm._e(),(_vm.is_adding || _vm.is_editing)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.submitReportCardGroupDetails}},[_vm._v("Save")]):_vm._e(),(_vm.report_card_group.is_draft)?_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.showPublishDialog = true}}},[_vm._v("Publish")]):_vm._e()],1),_c('intract-create-edit-sheet',{key:"report-card-group-edit-sheet",attrs:{"persistent":"","title":"Edit Details","description":"You can only edit the title of already generated report cards. To edit grades select the 'Edit Grades' option.","visible":_vm.editDetailsSheet.visible,"fields":_vm.editDetailsSheet.formFields,"submit-button-text":"Save","endpoint":_vm.endpoints.reportCardGroupViewSet,"partial-update":"","edit-id":_vm.report_card_group.id,"data-object":_vm.editDetailsSheet.group_details},on:{"close":function($event){_vm.editDetailsSheet.visible = false;
                _vm.editDetailsSheet.title = _vm.report_card_group.title;},"objectCreated":function (obj) { return _vm.report_card_group = obj; },"updateObject":function (obj) { return (_vm.editDetailsSheet.obj = obj); }}}),_c('v-dialog',{attrs:{"value":_vm.autoSaveDialog,"persistent":"","width":"300"}},[_c('v-card',{attrs:{"color":"red","dark":""}},[_c('v-card-text',[_c('strong',{staticClass:"my-2"},[_vm._v("Auto Saving Reports Cards..")]),(_vm.autoSaveDialog)?_c('v-progress-linear',{staticClass:"my-2",attrs:{"indeterminate":"","color":"white"}}):_vm._e()],1)],1)],1),_c('confirmation-dialog',{attrs:{"visible":_vm.showDeleteDialog,"title":"Are you sure you want to delete all the report cards?","description":"This action cannot be undone.","delete-dialog":""},on:{"successCallback":_vm.deleteReportCardGroup,"failureCallback":function($event){_vm.showDeleteDialog = false}}}),_c('confirmation-dialog',{attrs:{"visible":_vm.showPublishDialog,"title":"Are you sure you want to publish all the report cards?","description":"A notification will be sent when published. Published report cards are visibile to students. This action cannot be undone, however report cards can be edited. To make any other changes like classroom or grading parameters, you will have to delete all the report cards of this group."},on:{"successCallback":_vm.publishReportCard,"failureCallback":function($event){_vm.showPublishDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }